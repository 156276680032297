import React from 'react';
import {
    Box
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import SignUpComponent from '../components/Auth/Signup/SignupModal2';
import Head from 'next/head'
import { useAuth } from '../utils/auth/newAuth';
const LoginPage = () => {
    const router = useRouter();

    const { user, loading, profile } = useAuth();

    if (profile) {
        router.push('/listings')
    }

    return (
        <>
            <Head>
                <title key="title">Login - Zonado</title>
                <link rel="canonical" href="https://zonado.com/login" />
            </Head>

            <Box
               margin="auto 0"
            >
                <SignUpComponent />
            </Box>

        </>
    );
}

export default LoginPage;

